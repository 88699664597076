import { CURRENCY_TYPE, CountryCode } from "CONSTANTS";
import { Order } from "api/orders/models";
import { UUID } from "api/types";
import { ShippingService } from "constants/shippingServiceConstants";

export type CourierBooking = {
  shipmentNotificationNumber: string;
  shipmentTrackingNumber: string;
  packagesTrackingNumbers: null;
  dispatchNotificationNumber: string;
  label: {
    labelType: string;
    labelFormat: string;
    labelContent: string;
  };
};

export enum AddedMethodChoices {
  "AUTOMATICALLY" = "AUTOMATICALLY",
  "MANUALLY" = "MANUALLY",
}
export interface ShippingPiece {
  height: string;
  ambroPackageType: AmbroDefaultPackageType;
  id: UUID;
  isIncludedForSend: boolean;
  addedMethod: AddedMethodChoices;
  itemId: number | null;
  isStandard: boolean;
  length: string;
  name: string;
  packageType: string;
  productId: number;
  productName: string;
  shipment: UUID;
  trackingNumber: string;
  weight: string;
  width: string;
}

export enum AuthorizationStatus {
  NA = "NA",
  SUCCESS = "SUCCESS",
  FAILURE = "FAILURE",
}
export interface Courier {
  id: number;
  name: string;
  provider: ShippingService;
  username: string;
  password: string;
  authorizationStatusError: string;
  authorizedAt: string | null;
  dpdMasterFid: string;
  defaultPackageWeight: string;
  defaultPackageHeight: string;
  defaultPackageWidth: string;
  defaultPackageLength: string;
  clientNumber: string;
  integrationToken: string;
  token: string;
  isCalledPickupRequired: boolean;
  isConnected: boolean;
  ambroServicesOptions: { id: number; name: string }[];
  checkedConnectionAt: string | null;
  // AMBRO
  ambroDefaultService: number;
  ambroAdditionalServicesOptions: { id: string; name: string }[];
  ambroDefaultAdditionalServices: string[];
  ambroDefaultPackageType: AmbroDefaultPackageType;
  //GLS
  glsDefaultAdditionalServices: string[];
  glsDefaultAdditionalServicesOptions: { id: string; name: string }[];
}

export enum AmbroDefaultPackageType {
  FURNITURE = "Furniture",
  PACKAGE = "Package",
  SIZE20 = "Size20",
  LONG_PACKAGE = "LongPackage",
  PALETTE = "Palette",
}

export interface CreateCourier
  extends Pick<Courier, "password" | "username" | "provider" | "name"> {}

export interface ShipmentListItem {
  id: UUID;
  order: {
    id: Order["id"];
    salesAccount: Order["salesAccount"] | null;
    signature: Order["signature"];
  };
  externalProgress: number;
  isReturnShipment: boolean;
  lastStatusUpdate: string | null;
  shippingService: {
    id: number;
    isCalledPickupRequired: boolean;
    name: string;
    provider: ShippingService;
    // AMBRO
    ambroAdditionalServicesOptions: { id: string; name: string }[];
    ambroServicesOptions: { id: number; name: string }[];
    // GLS
    glsDefaultAdditionalServicesOptions: { id: string; name: string }[];
  } | null;
  trackingNumbers: string[];
  createExternalShipmentAt: string | null;
  courierPickupDate: string | null;
  courierDeliveredDate: string | null;
  shipmentDeadline: string | null;
  /**
   * status generated by backend
   */
  status: ShipmentStatus;
  /**
   * Status that we fetch from external delivery
   */
  externalStatus: string;
  cashOnDelivery: string | null;
  countryCode: CountryCode;
  currency: CURRENCY_TYPE;
}

export interface Shipment extends ShipmentListItem {
  ambroService: number;
  ambroAdditionalServices: string[];
  glsAdditionalServices: string[];
  ambroPackageType: AmbroDefaultPackageType;
  createdAt: string;
  trackingNumber: string;
  pickupId: UUID;
  plannedPickupArrivalDate: string | null;
  plannedPickupArrivalTime: string | null;
  errorNote: string;
  hasAssemblingService: boolean;
  hasCarryingService: boolean;
  companyName: string;
  firstName: string;
  lastName: string;
  street: string;
  city: string;
  postCode: string;
  phone: string;
  email: string;
  pickupCountryCode: string;
  pickupCompanyName: string;
  pickupFirstName: string;
  pickupLastName: string;
  pickupStreet: string;
  pickupCity: string;
  pickupPostCode: string;
  pickupPhone: string;
  pickupEmail: string;
  isInvoiceRequired: boolean;
  isShipmentNotified: boolean;
}

export enum ShipmentStatus {
  NO_REGISTERED = "NO_REGISTERED",
  REGISTRATION_IN_PROGRESS = "REGISTRATION_IN_PROGRESS",
  CREATE_SHIPMENT_SUCCESS = "CREATE_SHIPMENT_SUCCESS",
  CREATE_SHIPMENT_FAILED = "CREATE_SHIPMENT_FAILED",
  CALLED_PICKUP_SUCCESS = "CALLED_PICKUP_SUCCESS",
  CALLED_PICKUP_FAILED = "CALLED_PICKUP_FAILED",
  CANCELLATION_IN_PROGRESS = "CANCELLATION_IN_PROGRESS",
  CANCELED_SHIPMENT_SUCCESS = "CANCELED_SHIPMENT_SUCCESS",
  CANCELED_SHIPMENT_FAILED = "CANCELED_SHIPMENT_FAILED",
}

export interface PostInternalShipmentsPreview {
  success: string[];
  signaturesOrdersWithoutCountryCode: string[];
  signaturesOrdersWithShipments: string[];
  signaturesOrdersWithoutIndexes: string[];
}

export interface PostShipmentErrorPreview {
  missingPackages: {
    id: UUID;
    signature: string;
  }[];
  missingPlannedPickupAt: {
    id: UUID;
    signature: string;
  }[];
  missingShippingService: {
    id: UUID;
    signature: string;
  }[];
}
