import { shippingActions } from "api/shipping/actions";
import { Shipment } from "api/shipping/models";
import { InfoLabel } from "components/common/infoLabel";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { Select } from "components/miloDesignSystem/molecules/select";
import { useSelector } from "hooks";
import { cx, dateFns, dateUtils } from "utilities";
import {
  EMPTY_VALUE,
  EmptyValue,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import styles from "../../RightPanel.module.css";
import { DatePicker } from "components/utils/datePicker";
import { TimePicker } from "components/utils/timePicker";
import { Link } from "components/miloDesignSystem/atoms/link";
import { AsyncInput } from "components/utils";
import { CURRENCY_TYPE, currenciesToPick } from "CONSTANTS";
import { CopyHandler } from "components/common/copyHandler/CopyHandler";
import { TextField } from "components/miloDesignSystem/atoms/textField";
import { shippingServiceConstants } from "constants/shippingServiceConstants";
import { useQueryClient } from "react-query";
import { useScrollableContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import { MarketplaceShipping } from "components/common/marketplaceShipping/MarketplaceShipping";
import { assertIsDefined } from "utilities/assertIsDefined";
import { AdvanceShipmentNotification } from "../advanceShipmentNotification/AdvanceShipmentNotification";

interface Props {
  shipment: Shipment;
}

export const GeneralInfoSection = ({ shipment }: Props) => {
  const queryClient = useQueryClient();
  const shippingServices = useSelector(state => state.partials.shippingShippingServices);
  const assignShipmentProviderMutation = shippingActions.useAssignShipmentProvider();
  const plannedPickupArrivalDateMutation = shippingActions.usePatchShipping();
  const plannedPickupArrivalTimeMutation = shippingActions.usePatchShipping();
  const cashOnDeliveryMutation = shippingActions.usePatchShipping();
  const currencyMutation = shippingActions.usePatchShipping();
  const canUpdateTrackingNumber =
    shipment.shippingService?.provider &&
    shippingServiceConstants[shipment.shippingService.provider].permissions.canUpdate
      .trackingNumber;
  const context = useScrollableContext();

  return (
    <div className="px-3 pt-2 pb-2">
      <div ref={context.triggerElementRef}>
        <Link
          fontSize="26"
          fontWeight="700"
          theme="dark"
          to={`/orders/list/active/all?panelId=${shipment.order.id}`}
        >
          {shipment.order.signature}
        </Link>
      </div>
      {!canUpdateTrackingNumber && (
        <div className="d-flex align-items-center gap-1">
          {shipment.trackingNumber ? (
            <CopyHandler copyText={shipment.trackingNumber}>
              <Typography fontSize="18" fontWeight="700">
                {shipment.trackingNumber}
              </Typography>
            </CopyHandler>
          ) : (
            <EmptyValue fontSize="18" fontWeight="700" />
          )}
        </div>
      )}

      {shipment.errorNote && (
        <div className={cx(styles.errorWrapper, "d-flex flex-1 align-items-center p-2 mt-3 gap-2")}>
          <div>
            <Typography fontSize="12" fontWeight="600" color="danger500" noWrap className="flex-1">
              Wystąpił następujący błąd:
            </Typography>
          </div>

          <div className="w-75">
            <Tag label={shipment.errorNote} variant="danger" type="outlined" />
          </div>
        </div>
      )}
      <div className="d-flex align-items-start justify-content-between gap-2 mt-3">
        <div className="flex-1">
          {canUpdateTrackingNumber && (
            <InfoLabel title="nr przesyłki">
              <TextField.Async
                size="small"
                mutationHook={shippingActions.usePatchShipping}
                transformQueryData={trackingNumber => ({
                  id: shipment.id,
                  toUpdate: { trackingNumber },
                })}
                value={shipment.trackingNumber}
                placeholder="Wpisz nr przesyłki"
              />
            </InfoLabel>
          )}

          <InfoLabel title="pobranie">
            <div className="d-flex align-items-center gap-2">
              <AsyncInput
                disabled={cashOnDeliveryMutation.isLoading}
                inProgress={cashOnDeliveryMutation.isLoading}
                onChange={value =>
                  cashOnDeliveryMutation.mutate({
                    id: shipment.id,
                    toUpdate: { cashOnDelivery: value },
                  })
                }
                overwrites={{
                  container: { className: styles.cashOnDelivery },
                  input: { className: styles.input },
                  floatingLabel: { className: styles.floatingLabel },
                }}
                placeholder="pobranie"
                value={shipment.cashOnDelivery || ""}
              />
              <Select
                className={styles.currency}
                items={currenciesToPick.map(currency => ({
                  value: currency.value,
                  text: currency.label,
                  type: MenuItemType.TEXT,
                }))}
                onChange={currency => {
                  if (currency) {
                    currencyMutation.mutate({
                      id: shipment.id,
                      toUpdate: {
                        currency: currency as CURRENCY_TYPE,
                      },
                    });
                  }
                }}
                selected={shipment.currency || null}
              />
            </div>
          </InfoLabel>
          <InfoLabel title="kurier">
            <Select
              label="Wybierz kuriera"
              items={[
                {
                  text: EMPTY_VALUE,
                  type: MenuItemType.TEXT,
                  value: 0,
                },
              ].concat(
                ...shippingServices.map(shippingService => ({
                  value: shippingService.id,
                  text: shippingService.name,
                  type: MenuItemType.TEXT,
                })),
              )}
              onChange={value => {
                if (!value) {
                  assignShipmentProviderMutation.mutate(
                    { shipments: [shipment.id], shippingService: null },
                    { onSuccess: () => queryClient.invalidateQueries() },
                  );
                  return;
                }
                const shippingService = shippingServices.find(service => service.id === value);
                assertIsDefined(shippingService);
                assignShipmentProviderMutation.mutate(
                  { shipments: [shipment.id], shippingService },
                  { onSuccess: () => queryClient.invalidateQueries() },
                );
              }}
              selected={shipment.shippingService?.id || null}
            />
          </InfoLabel>
          <InfoLabel title="status u kuriera">
            {shipment.externalStatus && shipment.lastStatusUpdate ? (
              <div className="d-flex gap-2 align-items-center">
                <Tag label={shipment.externalStatus} variant="info" type="outlined" />

                <Typography fontSize="12" fontWeight="600" color="neutralBlack48">
                  w statusie od:{" "}
                </Typography>
                <Typography fontSize="12" fontWeight="600">
                  {dateFns.format(new Date(shipment.lastStatusUpdate), "dd.MM.yyyy, HH:mm")}
                </Typography>
              </div>
            ) : (
              <EmptyValue fontSize="14" />
            )}
          </InfoLabel>
          <InfoLabel title="data odbioru przez kuriera">
            <div className="d-flex align-items-center gap-2">
              <DatePicker
                disabled={plannedPickupArrivalDateMutation.isLoading}
                look="common"
                overwrites={{
                  popup: { className: styles.datePickerPopup },
                  input: { className: styles.datePickerInput },
                  container: { className: cx(styles.datePickerInput, "pl-0") },
                }}
                value={shipment.plannedPickupArrivalDate}
                onChange={date => {
                  return plannedPickupArrivalDateMutation.mutate({
                    id: shipment.id,
                    toUpdate: {
                      plannedPickupArrivalDate: date
                        ? dateFns.format(new Date(date), "yyyy-MM-dd")
                        : null,
                    },
                  });
                }}
              />
              <TimePicker
                disabled={plannedPickupArrivalTimeMutation.isLoading}
                inProgress={plannedPickupArrivalTimeMutation.isLoading}
                look="common"
                onBlur={time =>
                  plannedPickupArrivalTimeMutation.mutate({
                    id: shipment.id,
                    toUpdate: { plannedPickupArrivalTime: time || null },
                  })
                }
                overwrites={{
                  container: { className: styles.formHeight },
                  input: { className: styles.formHeight },
                }}
                value={shipment.plannedPickupArrivalTime}
              />
            </div>
          </InfoLabel>
          <InfoLabel title="odebrano przez kuriera">
            {shipment.courierPickupDate ? (
              <Typography fontSize="14" fontWeight="700">
                {dateUtils.formatDateToDisplayOnlyDate(shipment.courierPickupDate)}
              </Typography>
            ) : (
              <EmptyValue fontSize="14" />
            )}
          </InfoLabel>
          <InfoLabel title="dostarczono przez kuriera">
            {shipment.courierDeliveredDate ? (
              <Typography fontSize="14" fontWeight="700">
                {dateUtils.formatDateToDisplayOnlyDate(shipment.courierDeliveredDate)}
              </Typography>
            ) : (
              <EmptyValue fontSize="14" />
            )}
          </InfoLabel>
          <InfoLabel title="numer zlecenia">
            {Boolean(shipment.pickupId) ? (
              <CopyHandler copyText={shipment.pickupId}>
                <Typography fontSize="14" fontWeight="700">
                  {shipment.pickupId}
                </Typography>
              </CopyHandler>
            ) : (
              <EmptyValue fontSize="14" fontWeight="700" />
            )}
          </InfoLabel>
          <MarketplaceShipping orderId={shipment.order.id} />
          <AdvanceShipmentNotification shipment={shipment} />
        </div>
      </div>
    </div>
  );
};
